export const image = {
  //header
  logo: require("../../../assets/images/aaradhanalogo.png"),
  // temple: require('../../../assets/images/Homeimages/temple.png'),
  temple: require("../../../assets/images/Homeimages/Mandir.png"),
  qrCode: require("../../../assets/images/Homeimages/qrcode.png"),

  // footer
  aaradhanaVector: require("../../../assets/images/Footerimages/Vector.png"),
  facebook: require("../../../assets/images/Footerimages/facebook.png"),
  instagram: require("../../../assets/images/Footerimages/instagram.png"),
  linkedin: require("../../../assets/images/Footerimages/linkedin.png"),
  twitter: require("../../../assets/images/Footerimages/twitter.png"),
  facebook: require("../../../assets/images/Footerimages/facebook.png"),
  call: require("../../../assets/images/Footerimages/call.png"),
  message: require("../../../assets/images/Footerimages/message.png"),
  returnHomeRocket: require("../../../assets/images/Footerimages/returnHomeRocket.png"),
  location: require("../../../assets/images/Footerimages/location.png"),
  allrightslogo: require("../../../assets/images/Footerimages/allrightslogo.png"),

  // namaste Template
  namastemale: require("../../../assets/images/Homeimages/namastemale.png"),
  namastefemale: require("../../../assets/images/Homeimages/namastefemale.png"),
  fadetemple: require("../../../assets/images/Homeimages/fadetemple.png"),
  hindutemple: require("../../../assets/images/Homeimages/hindutemple.png"),

  // feature Template
  iphone1: require("../../../assets/images/Homeimages/iphone1.png"),
  iPhone2: require("../../../assets/images/Homeimages/iPhone2.png"),
  iPhone3: require("../../../assets/images/Homeimages/iPhone3.png"),
  star: require("../../../assets/images/Homeimages/star.png"),
  rings: require("../../../assets/images/Homeimages/rings.png"),
  aaradhanafadefeatures: require("../../../assets/images/Homeimages/aaradhanafadefeatures.png"),

  //Experience Template
  maharaj: require("../../../assets/images/Homeimages/maharaj.png"),
  starImage: require("../../../assets/images/Homeimages/starimage.png"),

  // Community Donation Template
  communityDonationMobile: require("../../../assets/images/Homeimages/communityDonationMobile.png"),
  communitydonationlogo: require("../../../assets/images/Homeimages/communitydonationlogo.png"),
  hexagon: require("../../../assets/images/Homeimages/hexagon.png"),
  cube: require("../../../assets/images/Homeimages/cube.png"),

  // Book puja
  ganpatiyagya: require("../../../assets/images/Homeimages/ganpatiyagya.png"),
  mahabhairavyagya: require("../../../assets/images/Homeimages/mahabhairavyagya.png"),
  krishnayagya: require("../../../assets/images/Homeimages/krishnayagya.png"),

  // event management
  eventiphone: require("../../../assets/images/Homeimages/eventiphone.png"),
  notificationevent: require("../../../assets/images/Homeimages/notificationevent.png"),

  //Donateto help
  donationone: require("../../../assets/images/Homeimages/donationone.png"),
  donationtwo: require("../../../assets/images/Homeimages/donationtwo.png"),
  donationthree: require("../../../assets/images/Homeimages/donationthree.png"),

  // instaFeed
  instastar: require("../../../assets/images/Homeimages/instastar.png"),
  iphoneinsta: require("../../../assets/images/Homeimages/iphoneinsta.png"),
  iphoneinstatwo: require("../../../assets/images/Homeimages/iphoneinstatwo.png"),

  //download app
  uppercircle: require("../../../assets/images/Homeimages/uppercircle.png"),
  bottomcircle: require("../../../assets/images/Homeimages/bottomcircle.png"),
  DarkStar: require("../../../assets/images/Homeimages/DarkStar.png"),
  LiteStar: require("../../../assets/images/Homeimages/LiteStar.png"),
  AppleIcon: require("../../../assets/images/Homeimages/AppleIcon.png"),
  playstoreIcon: require("../../../assets/images/Homeimages/playstoreIcon.png"),

  //testimonial temp

  testmonialfour: require("../../../assets/images/Homeimages/testmonialfour.jpg"),
  testmonialone: require("../../../assets/images/Homeimages/testmonialone.jpg"),
  testmonialthree: require("../../../assets/images/Homeimages/testmonialthree.jpg"),
  testmonialtwo: require("../../../assets/images/Homeimages/testmonialtwo.jpg"),

  // common
  noImageAvailable: require("../../../assets/images/Homeimages/noImageAvailable.jpg"),

  // footer images
  footerimageone: require("../../../assets/images/Footerimages/footerimageone.png"),
  footerimagetwo: require("../../../assets/images/Footerimages/footerimagetwo.png"),
};
