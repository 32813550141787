import React from 'react'
import './poojabenefits.scss'
import { IoIosArrowDropright } from 'react-icons/io'
import Loader from '../../../atom/Loader/Loader'

const Poojabenefits = ({ PoojaBenefitsData }) => {
  // const benefitsData = [
  //   {
  //     title: 'Get Freedom from financial debts',
  //     description:
  //       'By performing Rin Mukti Puja, a person gets freedom from all kinds of financial problems and achieves financial stability in life.',
  //   },
  //   {
  //     title: 'Gain Peace of Mind',
  //     description: 'The puja helps in alleviating stress and brings mental peace and stability to an individual.',
  //   },
  //   {
  //     title: 'Attract Prosperity',
  //     description: 'Regularly performing the puja can attract prosperity and abundance into your life.',
  //   },
  // ]
  // console.log('PoojaBenefitsData', PoojaBenefitsData)

  return (
    <div className="puja-benefits">
      <div className="puja-benefits__title">Benefits of Puja</div>
      {
        PoojaBenefitsData
          ? PoojaBenefitsData.map((benefit) => (
              <div key={benefit.id} className="puja-benefits__list">
                <div className="puja-benefits__item">
                  <IoIosArrowDropright className="poojadetails-arrow" />
                  <div className="puja-benefits__item-description">{benefit.title}</div>
                </div>
                <div className="puja-benefits__item-details">{benefit.description}</div>
              </div>
            ))
          : '' // <Loader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
      }
    </div>
  )
}

export default Poojabenefits
