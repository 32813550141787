import React, { forwardRef, useEffect, useRef, useState } from 'react'
import './DonateToHelpTemp.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getDonationPlans } from '../../../Redux/ApiCalls/Home/landingPage'
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa'
import DonationCard from '../../atom/donationCard/donationCard'

const DonateToHelpTemp = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [loadingMore, setLoadingMore] = useState(false)
  const itemsPerPage = 4

  const { donationData, donationPagination } = useSelector((state) => state.landingPage)
  const { count, currentPage, limit } = donationPagination

  const totalPages = Math.ceil(count / itemsPerPage)
  const containerRef = useRef(null)

  useEffect(() => {
    fetchDonationPlans(1)
  }, [])

  // Function to fetch donation plans
  const fetchDonationPlans = (page) => {
    if (loadingMore) return

    setLoadingMore(true)
    dispatch(getDonationPlans({ limit: itemsPerPage, page }))
      .then(() => {
        setLoadingMore(false)
      })
      .catch(() => {
        setLoadingMore(false)
      })
  }

  const handleNext = () => {
    if (currentIndex < totalPages - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1)
      fetchDonationPlans(currentPage + 1)
    }
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1)
      // fetchDonationPlans(currentIndex) // Fetch the previous page
    }
  }

  return (
    <div id="donations" ref={ref} className="donatehelp-container">
      <div className="donatehelp-content">
        <div className="donatehelp-title">DONATE TO HELP</div>
        <div className="donatehelp-subtitle">Make a Donation to Help the Community</div>
        {donationData.length > 0 && (
          <div className="swipe-buttons">
            <FaAngleDoubleLeft size={'3.604vw'} color="orange" onClick={handlePrev} />
            <FaAngleDoubleRight size={'3.604vw'} color="orange" onClick={handleNext} />
          </div>
        )}
        <div className="donatehelp-cardcontainer" ref={containerRef} style={{ overflowX: 'auto', maxHeight: '70vh' }}>
          <div className="dummy-div" style={{ transform: `translateX(-${currentIndex * 100}%)`, transition: 'transform 0.7s ease-in-out' }}>
            {donationData && donationData.length > 0 ? (
              donationData.map((item, index) => <DonationCard key={index} item={item} />)
            ) : (
              <div className="no-donation-available">No donations available.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})

export default DonateToHelpTemp
