import React, { useEffect, useState } from 'react'
import './aboutpooja.scss'
import { image } from '../../../../app/utils/common'
import Loader from '../../../atom/Loader/Loader'

export const Aboutpooja = ({ AboutPoojaData }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [shortDescription, setShortDescription] = useState('')

  useEffect(() => {
    if (AboutPoojaData?.description) {
      setShortDescription(AboutPoojaData.description.split('. ')[0] + '.')
    }
  }, [AboutPoojaData])

  const handleToggle = () => {
    setIsExpanded((prev) => !prev)
  }

  return (
    <div className="about-pooja">
      <div className="about-pooja__title">About Pooja</div>
      {AboutPoojaData ? (
        <>
          <div className="about-pooja__content">
            <div className="about-pooja__image">
              {AboutPoojaData.pooja_media && AboutPoojaData.pooja_media[0].image != undefined && AboutPoojaData.pooja_media.length > 0 ? (
                <img src={AboutPoojaData.pooja_media[0].image} alt="Rinmukteshwar Mahadev Temple" />
              ) : (
                <img src={image.noImageAvailable} alt="No Image Available" />
              )}
            </div>
            <div className="about-pooja__description">
              {isExpanded ? AboutPoojaData.description : shortDescription}
              <span className={`about-pooja__toggle ${isExpanded ? 'about-pooja__read-less' : 'about-pooja__read-more'}`} onClick={handleToggle}>
                {isExpanded ? ' read less' : ' read more'}
              </span>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default Aboutpooja
