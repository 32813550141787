import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosClient from '../../../app/utils/AxiosClient/axiosClient'

export const getPoojaPlans = createAsyncThunk('landingPage/getPoojaPlans', async ({ limit, page }) => {
  const response = await axiosClient.get('/pooja/plans', {
    params: {
      limit,
      page,
      landingPageAccess: true,
    },
  })
  // console.log('response', response)
  return response
})

export const getDonationPlans = createAsyncThunk('landingPage/getDonationPlans', async ({ limit, page }) => {
  const response = await axiosClient.get('/donationplans', {
    params: {
      limit,
      page,
      landingPageAccess: true,
    },
  })
  // console.log('first', response)
  return response
})

export const getonePoojaPlan = createAsyncThunk('landingPage/getonePoojaPlan', async ({ Id }) => {
  const response = await axiosClient.get(`/pooja/plan/${Id}`, {
    params: {
      landingPageAccess: true,
    },
  })
  // console.log('getonePoojaPlan', response)
  return response
})
