import React, { forwardRef, useEffect, useState } from "react";
import "./TestimonialTemplate.scss";
import CircularTestimonial from "../../atom/CircularTestimonial/CircularTestimonial";
import { image } from "../../../app/utils/common";

const testimonials = [
  {
    image: image.testmonialone,
    quote: "The best Spiritual Community Funding app ever!",
    description:
      "As a user, I can confidently say this is the best spiritual community funding app ever! It has made it so easy to connect with others and support meaningful initiatives within our faith community.",
    author: "Meenakshi Temple",
  },
  {
    image: image.testmonialfour,
    quote: "A transformative experience.",
    description:
      "This app has truly changed the way I engage with my community. It has opened doors for supporting others in ways I never thought possible.",
    author: "Somnath Temple",
  },
  {
    image: image.testmonialtwo,
    quote: "Connecting with my faith like never before.",
    description: "The features and the ease of use have allowed me to grow my spiritual journey and connect with others who share the same passion.",
    author: "Brihadeeswarar Temple",
  },
  {
    image: image.testmonialthree,
    quote: "An essential tool for community support.",
    description:
      "I have used many platforms, but this one stands out for its focus on community and spirituality. It’s a must-have for anyone looking to make a difference.",
    author: "Jagannath Temple",
  },
];

const TestimonialTemplate = forwardRef((props, ref) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const currentTestimonial = testimonials[currentIndex];

  return (
    <div id="testimonials" ref={ref} className="testimonial">
      <div className="testimonial__title">TESTIMONIAL</div>
      <div className="testimonial__subtitle">
        <span>What our users say</span> <span>about us?</span>
      </div>

      <div className="testimonial_animation-information">
        <div className="testimonial__animation">
          <CircularTestimonial />
        </div>
        <div className="testimonial__content">
          <div className="testimonial__quote">{currentTestimonial.quote}</div>
          <div className="testimonial__description">{currentTestimonial.description}</div>
          <div className="testimonial__images">
            {testimonials.map((testimonial, index) => (
              <img
                key={index}
                src={testimonial.image}
                alt={`Testimonial ${index + 1}`}
                className={`testimonial__image ${index === currentIndex ? "visible" : "hidden"}`}
              />
            ))}
          </div>

          <div className="testimonial__author">{currentTestimonial.author}</div>
        </div>
      </div>
      <img src={image.DarkStar} alt="" className="darkstar-img-tt" />
    </div>
  );
});

export default TestimonialTemplate;
