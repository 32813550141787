import React, { useState } from 'react'
import './allfaqs.scss'

export const Allfaqs = (props) => {
  const faqsData = [
    {
      question: 'Why Aradhanaa for online Puja Booking?',
      answer:
        'Aradhanaa is a reliable online platform through which you can book puja in the famous temples of the country. The puja booked by you is performed by the priests at the auspicious time. After the puja, they deliver the offerings of the puja within the specified time.',
    },
    {
      question: 'What types of pujas can I book through Aradhanaa?',
      answer:
        'You can book various types of pujas, including daily rituals, special occasion pujas, and personalized pujas for specific needs and desires.',
    },
    {
      question: 'How do I know if my booking is confirmed?',
      answer:
        'You will receive a confirmation email with all the details of your booking, including the date, time, and the priest who will perform the puja.',
    },
    {
      question: 'Can I reschedule my puja booking?',
      answer: 'Yes, you can reschedule your puja booking by contacting our support team at least 24 hours in advance.',
    },
    {
      question: 'Are the priests qualified and experienced?',
      answer:
        'Yes, all the priests are qualified, experienced, and have been carefully selected to ensure that they perform the pujas according to the traditional rituals.',
    },
  ]

  const [openIndex, setOpenIndex] = useState(null)

  const toggleFAQ = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <div className="faqs-container">
      <div className="faqs-title">FAQs</div>
      {faqsData.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            {faq.question}
          </div>
          {openIndex === index && <div className="faq-answer">{faq.answer}</div>}
        </div>
      ))}
    </div>
  )
}

export default Allfaqs
