import React, { useState, useRef } from 'react'
import './poojadetailstabs.scss'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Box } from '@mui/material'
import Poojabenefits from './poojabenefits/poojabenefits'
import Abouttemple from './abouttemple/abouttemple'
import Aboutpooja from './aboutpooja/aboutpooja'
import Poojapackages from './poojapackages/poojapackages'
import Allfaqs from './allfaqs/allfaqs'
import CustomerTestimonial from './customertestimonials/customertestimonial'

const Poojadetailstabs = ({ CardData }) => {
  const [value, setValue] = useState(0)

  const tabs = [
    { label: 'Benefit of Puja', component: <Poojabenefits PoojaBenefitsData={CardData.pooja_benefits} /> },
    { label: 'About Temple', component: <Abouttemple AboutTempleData={CardData.church} /> },
    { label: 'About Puja', component: <Aboutpooja AboutPoojaData={CardData} /> },
  ]

  const refs = useRef(tabs.map(() => React.createRef()))

  const handleChange = (event, newValue) => {
    setValue(newValue)
    refs.current[newValue].current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="tabs-pooja-details">
      <Tabs value={value} onChange={handleChange} aria-label="tabs example">
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>

      <Box p={3}>
        {tabs.map((tab, index) => (
          <div ref={refs.current[index]} key={index}>
            {tab.component}
          </div>
        ))}
        {/* <Poojapackages /> */}
        <Allfaqs />
        <CustomerTestimonial />
      </Box>
    </div>
  )
}

export default Poojadetailstabs
