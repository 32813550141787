import { createSlice } from "@reduxjs/toolkit";
import { getDonationPlans, getonePoojaPlan, getPoojaPlans } from "../../ApiCalls/Home/landingPage";

const initialState = {
  poojaData: [],
  singlePoojaData: {},
  donationData: [],
  loading: false,
  error: null,
  status: null,
  message: null,
  poojaPagination: {
    count: 0,
    currentPage: 0,
    limit: 0,
  },
  donationPagination: {
    count: 0,
    currentPage: 0,
    limit: 0,
  },
};

const landingPageSlice = createSlice({
  name: "landingPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Pooja Plans
    builder
      .addCase(getPoojaPlans.pending, (state) => {
        state.loading = true;
        state.error = null;
        console.log("Pooja payload", state);
      })
      .addCase(getPoojaPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload.status;
        state.message = action.payload.message;
        state.poojaData = [...state.poojaData, ...action.payload.data.data];
        state.poojaPagination.count = action.payload.data.count;
        state.poojaPagination.currentPage = action.payload.data.page;
        state.poojaPagination.limit = action.payload.data.limit;
        console.log("Pooja payload", action);
      })
      .addCase(getPoojaPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Donation Plans
      .addCase(getDonationPlans.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDonationPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload.status;
        state.message = action.payload.message;
        state.donationData = [...state.donationData, ...action.payload.data.data];
        state.donationPagination.count = action.payload.data.count;
        state.donationPagination.currentPage = action.payload.data.page;
        state.donationPagination.limit = action.payload.data.limit;
        console.log("Donation payload", state.donationPagination.limit);
      })
      .addCase(getDonationPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getonePoojaPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getonePoojaPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload.status;
        state.message = action.payload.message;
        state.singlePoojaData = action.payload.data.data;
        // console.log('Donation singlePoojaData', state.singlePoojaData)
      })
      .addCase(getonePoojaPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default landingPageSlice.reducer;
