import React, { useEffect, useState } from 'react'
import './poojadetailscard.scss'
import { image } from '../../../app/utils/common'
import { CiCalendarDate } from 'react-icons/ci'
import { Carousel } from 'antd'
import DateFormatter from '../DateFormattor'
import PoojaTimer from '../PoojaTimer'
import Loader from '../Loader/Loader'

const Poojadetailscard = ({ CardData }) => {
  // console.log('first', CardData.pooja_media[0].media_file)
  return (
    <div className="pooja-details-card">
      {CardData ? (
        <>
          <div className="pooja-details-card__image">
            <Carousel className=" carosal-styling" arrows infinite={true}>
              {CardData.pooja_media && CardData.pooja_media.length > 0 ? (
                CardData.pooja_media.map((item) => (
                  <div key={item.id}>
                    <img src={item.media_file} alt={item.id} />
                  </div>
                ))
              ) : (
                <div>
                  <img src={image.noImageAvailable} alt="No images available" />
                </div>
              )}
            </Carousel>
          </div>
          <div className="pooja-details-card__info">
            <div className="pooja-details-card__title">{CardData.title}</div>
            <div className="pooja-details-card__description">{CardData.description}</div>
            <div className="pooja-details-card__location">
              <div className="pooja-details-card__location-icon">
                <img src={image.location} alt="Location" />
              </div>
              <div className="pooja-details-card__location-text">
                {CardData.address}, {CardData.city}, {CardData.state}
              </div>
            </div>
            <div className="pooja-details-card__date">
              <div className="pooja-details-card__date-icon">
                <CiCalendarDate className="calender-icon" />
              </div>
              <div className="pooja-details-card__date-text">{CardData.date_time ? <DateFormatter dateString={CardData.date_time} /> : ''}</div>
            </div>
            <hr className="horizontal-line" />
            <div className="pooja-details-card__devotees">
              <div className="pooja-details-card__devotees-count">{CardData.count}</div>
              <div className="straight-line"></div>
              <div className="pooja-details-card__devotees-text">Devotees already booked this Puja</div>
            </div>
            <div className="pooja-details-card__timer">
              <div className="pooja-details-card__timer_container">
                <div className="pooja-details-card__timer-text">Puja starts in</div>
                <div className="pooja-details-card__timer-count">{CardData.date_time ? <PoojaTimer poojaDate={CardData.date_time} /> : ''}</div>
                <div className="pooja-details-card__timer-title">
                  <span>Day</span> <span>Hrs</span> <span>Min</span> <span>Sec</span>
                </div>
              </div>
              <div className="pooja-details-card__share">
                <div className="pooja-details-card__share-icon">
                  <img src={image.twitter} alt="" />
                </div>
                <div className="pooja-details-card__share-text">Share</div>
              </div>
            </div>
            <div className="pooja-details-card__button">Book Pooja</div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default Poojadetailscard
