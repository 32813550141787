import React, { useEffect, useState } from 'react'
import './CircularTestimonial.scss'
import { image } from '../../../app/utils/common'

const images = [image.testmonialone, image.testmonialfour, image.testmonialtwo, image.testmonialthree]

const CircularTestimonial = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 4000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="main-container" aria-label="Circular Testimonial Images">
      <div className="circle">
        <div
          key={0}
          className="profile-image"
          style={{
            backgroundImage: `url(${images[0]})`,
            top: '-2vw',
          }}
          role="img"
          aria-label={`Profile image ${1}`}
        />
        <div
          key={1}
          className="profile-image"
          style={{
            backgroundImage: `url(${images[1]})`,
            right: '-2vw',
          }}
          role="img"
          aria-label={`Profile image ${2}`}
        />
        <div
          key={2}
          className="profile-image"
          style={{
            backgroundImage: `url(${images[2]})`,
            bottom: '-2vw',
          }}
          role="img"
          aria-label={`Profile image ${3}`}
        />
        <div
          key={3}
          className="profile-image"
          style={{
            backgroundImage: `url(${images[3]})`,
            left: '-2vw',
          }}
          role="img"
          aria-label={`Profile image ${4}`}
        />
      </div>
      <div className="center-image" style={{ backgroundImage: `url(${images[currentImageIndex]})` }} role="img" aria-label="Current center image" />
    </div>
  )
}

export default CircularTestimonial
