import './BookPujaTemplate.scss'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPoojaPlans } from '../../../Redux/ApiCalls/Home/landingPage'
import { FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa'
import PoojaCard from '../../atom/poojaCard/poojaCard'

export const BookPujaTemplate = forwardRef((props, ref) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [loadingMore, setLoadingMore] = useState(false)
  const itemsPerPage = 4
  const dispatch = useDispatch()

  const { poojaData, poojaPagination } = useSelector((state) => state.landingPage)
  const { count, currentPage, limit } = poojaPagination

  const totalPages = Math.ceil(count / itemsPerPage)
  const containerRef = useRef(null)

  useEffect(() => {
    fetchPoojaPlans(1)
  }, [])

  const fetchPoojaPlans = (page) => {
    if (loadingMore) return

    setLoadingMore(true)
    dispatch(getPoojaPlans({ limit: itemsPerPage, page }))
      .then(() => {
        setLoadingMore(false)
      })
      .catch(() => {
        setLoadingMore(false)
      })
  }

  const handleNext = () => {
    if (currentPageIndex < totalPages - 1) {
      setCurrentPageIndex((prevIndex) => prevIndex + 1)
      fetchPoojaPlans(currentPage + 1)
    }
  }

  const handlePrev = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex((prevIndex) => prevIndex - 1)
    }
  }

  return (
    <div id="poojas" ref={ref} className="bookpuja-container">
      <div className="pujarti-container">
        <div className="puja-aarti">POOJA / AARTI</div>
        <div className="book-puja-aarti">
          <span>Book Poojas with </span>
          <span>Temples</span>
        </div>
        <div className="simplify-rituals">
          <span>Simplify your spiritual rituals by booking poojas and religious ceremonies through the app.</span>
          <span>Choose from a variety of pooja options and make your offerings with ease.</span>
        </div>
      </div>
      {poojaData && poojaData.length > 0 && (
        <div className="swipe-buttons">
          <FaAngleDoubleLeft size={'3.604vw'} color="orange" onClick={handlePrev} />
          <FaAngleDoubleRight size={'3.604vw'} color="orange" onClick={handleNext} />
        </div>
      )}
      <div className="pooja-cards-container" ref={containerRef} style={{ overflowX: 'auto', maxHeight: '70vh' }}>
        <div className="pooja-cards" style={{ transform: `translateX(-${currentPageIndex * 100}%)`, transition: 'transform 0.5s ease-in-out' }}>
          {poojaData && poojaData.length > 0 ? (
            poojaData.map((pooja) => <PoojaCard key={pooja.id} pooja={pooja} />)
          ) : (
            <div className="no-pooja-cards">No Pooja Plans Available</div>
          )}
        </div>
      </div>
    </div>
  )
})

export default BookPujaTemplate
