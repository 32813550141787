import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import PrivacyPolicy from "../../components/molecules/PrivacyPolicy/PrivacyPolicy";
import Footer from "../../components/molecules/Footer/Footer";
import Poojadetailspage from "../../components/organisms/poojadetailspage/poojadetailspage";
import Homepage from "../../components/organisms/homepage/homepage";
import RingLoader from "react-spinners/RingLoader";
import AradhanaaLogo from "../../assets/images/aaradhanalogo.png";

const DelayedRoute = ({ element: Element, delay = 1000 }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      navigate(window.location.pathname);
    }, delay);

    return () => clearTimeout(timer);
  }, [navigate, delay]);

  return loading ? (
    <div style={styles.loaderContainer}>
      <img src={AradhanaaLogo} alt="Aaradhanaa Logo" style={styles.logo} />
      <RingLoader color="#FF772D" size={80} />
    </div>
  ) : (
    <Element />
  );
};

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<DelayedRoute element={Homepage} />} />
        <Route path="/privacy" element={<DelayedRoute element={PrivacyPolicy} />} />
        <Route path="/poojadetails/:id" element={<DelayedRoute element={Poojadetailspage} />} />
        <Route path="/donations" element={<DelayedRoute element={() => <Homepage scrollToSection="donations" />} />} />
        <Route path="/poojas" element={<DelayedRoute element={() => <Homepage scrollToSection="poojas" />} />} />
        <Route path="/testimonials" element={<DelayedRoute element={() => <Homepage scrollToSection="testimonials" />} />} />
      </Routes>
      <Footer />
    </>
  );
};

// Loader Styles
const styles = {
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#ffffff",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 9999,
  },
  logo: {
    width: "20vw",
    marginBottom: "20px",
  },
};

export default AppRoutes;
