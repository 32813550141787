import React, { useState, useEffect } from 'react'
import './abouttemple.scss'
import { image } from '../../../../app/utils/common'
import Loader from '../../../atom/Loader/Loader'

const Abouttemple = ({ AboutTempleData }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  // const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [shortDescription, setshortDescription] = useState()

  // const templeData = {
  //   temple: {
  //     title: 'About Temple',
  //     description:
  //       'Rinmukteshwar Mahadev Temple is situated in the Valmiki Dham area on the banks of the Shipra River in the ancient city of Ujjain. This temple is one of the major religious places of Ujjain and is famous for liberating humans from various types of debts. According to beliefs, sage Vishwamitra asked King Harishchandra for gold as much as the weight of a rhinoceros in Satayuga. When King Harishchandra had already donated his Raj Path, he visited the Rinamukteshwar Mahadev Temple to get rid of the debt. After worshipping in the temple, he was freed from debt. Since then, Rinamukteshwar Mahadev has been saving his devotees from problems like debt through his kind vision.',
  //     imageUrls: [image.temple, image.fadetemple],
  //   },
  // }

  // const images = templeData.temple.imageUrls

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
  //   }, 1000)

  //   return () => clearInterval(interval)
  // }, [images.length])

  const handleToggle = () => {
    setIsExpanded((prev) => !prev)
  }

  useEffect(() => {
    if (AboutTempleData?.about) {
      setshortDescription(AboutTempleData.about.split('. ')[0] + '.')
    }
  }, [AboutTempleData])

  return (
    <div className="about-temple">
      <div className="about-temple-title">About Temple</div>
      {AboutTempleData ? (
        <>
          <div className="about-temple-content">
            <div className="about-temple-image">
              {AboutTempleData.image && AboutTempleData.image != undefined ? (
                <img src={AboutTempleData.image} alt="Rinmukteshwar Mahadev Temple" />
              ) : (
                <img src={image.noImageAvailable} alt="No Image Available" />
              )}
            </div>
            <div className="about-temple-description">
              {(isExpanded ? AboutTempleData.about : shortDescription) || 'There is no description available for this temple'}

              {(shortDescription || AboutTempleData.about) && (
                <span className={`about-temple-toggle ${isExpanded ? 'about-temple-read-less' : 'about-temple-read-more'}`} onClick={handleToggle}>
                  {isExpanded ? ' read less' : ' read more'}
                </span>
              )}
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default Abouttemple
