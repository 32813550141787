import React from 'react'
import './Loader.scss'

const Loader = ({ style = {} }) => {
  return (
    <div className="loader-container flex items-center justify-center" style={style}>
      <div className="lds-spinner">
        {[...Array(12)].map((_, i) => (
          <div key={i}></div>
        ))}
      </div>
    </div>
  )
}

export default Loader
