import React, { useState, useEffect } from 'react'

const PoojaTimer = ({ poojaDate }) => {
  const targetDate = new Date(poojaDate)
  const [timeLeft, setTimeLeft] = useState(null)
  const [hasTimePassed, setHasTimePassed] = useState(false)

  const calculateTimeLeft = () => {
    const now = new Date()
    const difference = targetDate - now

    if (difference <= 0) {
      setHasTimePassed(true)
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    }
  }

  useEffect(() => {
    if (!hasTimePassed) {
      const newTimeLeft = calculateTimeLeft()
      setTimeLeft(newTimeLeft)

      const timer = setInterval(() => {
        const newTimeLeft = calculateTimeLeft()
        setTimeLeft(newTimeLeft)
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [hasTimePassed])

  if (hasTimePassed) {
    return <p>The Pooja has started or passed!</p>
  }

  if (!timeLeft) {
    return null
  }

  return (
    <div>
      <span>
        {timeLeft.days} {timeLeft.days > 1 ? ':' : ':'}
      </span>
      <span>
        {timeLeft.hours} {timeLeft.hours > 1 ? ':' : ':'}
      </span>
      <span>
        {timeLeft.minutes} {timeLeft.minutes > 1 ? ':' : ':'}
      </span>
      <span>
        {timeLeft.seconds} {timeLeft.seconds > 1 ? '' : ''}
      </span>
    </div>
  )
}

export default PoojaTimer
