import React, { useState, useRef } from 'react'
import './customertestimonials.scss'
import { FcNext } from 'react-icons/fc'
import { FcPrevious } from 'react-icons/fc'

const CustomerTestimonial = () => {
  const testimonials = [
    {
      title: 'A Spiritual Revolution!',
      name: 'Jignesh Kubavat',
      location: 'Delhi, India',
      testimonial: 'Aradhanaa has truly transformed my spiritual practices. The app is user-friendly, and connecting with astrologers is a breeze!',
    },
    {
      title: 'Convenience at its Best',
      name: 'Ravi Kumar',
      location: 'Bangalore, India',
      testimonial: 'Booking Poojas in renowned temples was never this easy! I love the virtual Mandir feature. It feels like I’m right there!',
    },
    {
      title: 'Expert Guidance Anytime',
      name: 'Neha Desai',
      location: 'Mumbai, India',
      testimonial: 'The access to expert astrologers has been a game changer. Their insights are incredibly helpful in guiding my decisions.',
    },
    {
      title: 'Life-Changing Experience',
      name: 'Amit Singh',
      location: 'Chennai, India',
      testimonial: 'This app has made it so easy for me to stay connected with my spiritual journey. Highly recommended!',
    },
    {
      title: 'A Blessing for the Soul',
      name: 'Gaurav Lokhande',
      location: 'Amravati, Maharashtra',
      testimonial: 'The insights I’ve gained through the astrologers have truly blessed my life!',
    },
  ]

  const [currentIndex, setCurrentIndex] = useState(0)
  const testimonialRef = useRef(null)

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? Math.max(testimonials.length - 3, 0) : prevIndex - 1))
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex >= testimonials.length - 3 ? 0 : prevIndex + 1))
  }

  const displayedTestimonials = testimonials.slice(currentIndex, currentIndex + 3)

  return (
    <div className="testimonial-container">
      <div className="testimonial-heading">Customer Testimonials</div>
      <div className="testimonial-cards" ref={testimonialRef}>
        {displayedTestimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <h3>{testimonial.title}</h3>
            <p>{testimonial.testimonial}</p>
            <p className="testimonial-author">
              {testimonial.name}, <span>{testimonial.location}</span>
            </p>
          </div>
        ))}
        <div className="testimonial-navigation">
          <FcPrevious style={{ color: 'orange', cursor: 'pointer' }} className="testimonial-previous" onClick={handlePrev} />
          <FcNext style={{ color: 'orange', cursor: 'pointer' }} className="testimonial-next" onClick={handleNext} />
        </div>
      </div>
    </div>
  )
}

export default CustomerTestimonial
