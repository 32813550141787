import React from 'react'
import './poojaCard.scss'
import { image } from '../../../app/utils/common'
import DateFormatter from '../DateFormattor'
import { useNavigate } from 'react-router-dom'

const PoojaCard = ({ pooja }) => {
  const navigate = useNavigate()

  const handleClickBookPooja = (pooja) => {
    navigate(`/poojadetails/${pooja.id}`)
  }

  return (
    <div className="pooja-card">
      <div className="card-inside-image">
        {pooja.pooja_media && pooja.pooja_media.length > 0 ? (
          <img key={pooja.pooja_media[0].id} src={pooja.pooja_media[0].media_file} alt={`Media ${pooja.pooja_media[0].id}`} />
        ) : (
          <img src={image.noImageAvailable} alt="No image available" />
        )}
      </div>

      <div className="card-inside-info">
        <span className="card-title">{pooja.title}</span>
        <span className="card-subtitle">{pooja.sub_title}</span>
        <span className="card-location">{pooja.address}</span>
      </div>
      <span className="pooja-date">
        <DateFormatter dateString={pooja.date_time} />
      </span>
      <div className="book-puja-button" onClick={() => handleClickBookPooja(pooja)}>
        Book Pooja
      </div>
    </div>
  )
}

export default PoojaCard
