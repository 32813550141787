import './App.css'
import AppRoutes from './app/routes/AppRoutes'

function App() {
  return (
    <div style={{ backgroundColor: '#FFFFFF', overflowX: 'hidden' }}>
      <AppRoutes />
    </div>
  )
}

export default App
