import React from 'react'
import { image } from '../../../app/utils/common'
import './donationCard.scss'

const DonationCard = ({ item }) => {
  return (
    <div className="donation-card">
      <div className="card-inside-image">
        {item.image ? <img key={item.id} src={item.image} alt={`Media ${item.id}`} /> : <img src={image.noImageAvailable} alt="No image available" />}
      </div>

      <div className="card-inside-info">
        <span className="card-title">{item.title}</span>
        {/* <span className="card-subtitle">{item.sub_title}</span> */}
        <span className="card-location">{item.description}</span>
      </div>
      <span className="donation-date">
        <div>Amount: Rs.{item.amount || 0}</div>
      </span>
      <div className="donate-button">Donate Now</div>
    </div>
  )
}

export default DonationCard
