import axios from "axios";

// const BASE_URL = "http://159.89.163.122:8080";

const BASE_URL = "http://aradhanaa-lb-1740731875.ap-south-1.elb.amazonaws.com";

const axiosClient = axios.create({
  baseURL: BASE_URL,
});

axiosClient.interceptors.request.use(
  (config) => {
    // console.log('Request:', config)
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    // console.log('Response:', response)
    return response;
  },
  (error) => {
    // console.error('Response error:', error)
    return Promise.reject(error);
  }
);

export default axiosClient;
