import React, { useEffect } from 'react'
import './poojadetailspage.scss'
import { Steps } from 'antd'
import Header from '../../molecules/Header/Header'
import Poojadetailscard from '../../atom/poojadetailsCard/poojadetailscard'
import Poojadetailstabs from '../../molecules/poojadetailsTabs/poojadetailstabs'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../atom/Loader/Loader'
import { getonePoojaPlan } from '../../../Redux/ApiCalls/Home/landingPage'
import CheckoutStepper from '../../atom/stepper/stepper'
import { height, width } from '@mui/system'
import { Stepper } from 'react-form-stepper'

const CHECKOUT_STEPS = [
  { label: 'Choose Puja' },
  { label: 'Select Package' },
  { label: 'Review & Checkout' },
  { label: 'Sankalp Form' },
  { label: 'Live Puja & Prashad Delivery' },
]

function Poojadetailspage() {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { singlePoojaData, loading } = useSelector((state) => state.landingPage)

  useEffect(() => {
    if (id) {
      dispatch(getonePoojaPlan({ Id: id }))
    }
  }, [dispatch, id])

  return (
    <>
      <Header />
      <div className="pooja-details">
        {singlePoojaData && Object.keys(singlePoojaData).length > 0 ? (
          <>
            <div className="pooja-details-steps">
              <div className="pooja-details-step">
                <Stepper
                  className="custom-stepper"
                  styleConfig={{
                    size: '2em',
                    labelFontSize: '0.9em',
                    lineHeight: '2em',
                    activeBgColor: '#ed1d24',
                    inactiveBgColor: '#e0e0e0',
                    completedBgColor: '#4caf50',
                    borderColor: '#ccc',
                  }}
                  steps={CHECKOUT_STEPS}
                  activeStep={4}
                  circleFontSize="1em"
                  labelColor="#333"
                  activeLabelColor="#fff"
                  inactiveLabelColor="#666"
                />
              </div>
            </div>
            <div className="pooja-details-card-main">
              {singlePoojaData ? <Poojadetailscard CardData={singlePoojaData} /> : <div>No data available</div>}
            </div>
            <div className="multi-tabs">{singlePoojaData ? <Poojadetailstabs CardData={singlePoojaData} /> : <div>No data available</div>}</div>
          </>
        ) : (
          <Loader style={{ width: '100%', height: '100vh' }} />
        )}
      </div>
    </>
  )
}

export default Poojadetailspage
